:root {
  --nsblue: #47aed7;
  --nsforest: #0a854c;
  --nsgray: #d8dad9;
  --nsgreen: #d3e7dc;
  --nslime: #66fecb;
  --nsnavy: #336bad;
  --nsorange: #ee4116;
  --nspeach: #ffe1c5;
  --nspink: #f7cdcf;
  --nsred: #e11e2e;
  --nsyellow: #e2ba36;
}

@font-face {
  font-family: ChicagoFLF;
  src: url('/fonts/ChicagoFLF.woff2') format('woff2');
  font-display: swap;
}
.chicagoflf {
  font-family: ChicagoFLF, sans-serif;
}

.bg-nsblue {
  background-color: var(--nsblue);
}
.bg-nsforest {
  background-color: var(--nsforest);
}
.nsgray {
  color: var(--nsgray);
}
.bg-nsgray {
  background-color: var(--nsgray);
}
.bg-nsgreen {
  background-color: var(--nsgreen);
}
.bg-nsorange {
  background-color: var(--nsorange);
}
.bg-nslime {
  background-color: var(--nslime);
}
.nsnavy {
  color: var(--nsnavy);
}
.bg-nsnavy {
  background-color: var(--nsnavy);
}
.nspeach {
  color: var(--nspeach);
}
.bg-nspeach {
  background-color: var(--nspeach);
}
.nspink {
  color: var(--nspink);
}
.bg-nspink {
  background-color: var(--nspink);
}
.bg-nsred {
  background-color: var(--nsred);
}
.bg-nsyellow {
  background-color: var(--nsyellow);
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.invisible {
  pointer-events: none;
}

.appear-from-bottom {
  animation: appear-from-bottom 0.3s ease-in-out both;
}

.disappear-to-bottom {
  animation: disappear-to-bottom 0.3s ease-in-out both;
}

@keyframes appear-from-bottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes disappear-to-bottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.store-and-retrieve-desktop {
  display: block;
}

.store-and-retrieve-mobile {
  display: none;
}

.about-text {
  max-width: 32rem;
}

@media screen and (max-width: 75em) {
  .store-and-retrieve-desktop {
    display: none;
  }

  .store-and-retrieve-mobile {
    display: block;
  }

  .about-text {
    max-width: none;
  }
}
